@import "scss/colours.scss";

.demo-wrapper {
  position: relative;
}

.randomize-text-button {
  color: desaturate($primary, 30%) !important;
  opacity: 1;

  &:hover {
    color: desaturate($primary, 10%) !important;
    opacity: 0.85;
  }
}

.hanashi-demo-image {
  position: absolute;
  z-index: 4;
  top: -170px;
  right: 0;
  width: 385px;
}

@media (max-width: 992px) {
  .hanashi-demo-image {
    top: -97px;
    width: 220px;
    left: 55%;
    transform: translateX(-50%);
  }
}
